import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["field", "select"]

    connect() {
        this.selectTargets.forEach((select) => {
            if (select !== null && select.value === null || select.value.trim().length === 0) {
                select.style.fontWeight = "normal"
            } else {
                select.style.fontWeight = "bold"
            }
        })
    }

    updateField(event) {
        this.updateFormFieldValue(event)
        this.updateSelectElements(event)
    }

    updateFormFieldValue(event) {
        this.fieldTarget.value = event.target.value
    }

    // Clear other select boxes and style accordingly
    updateSelectElements(event) {
        this.selectTargets.forEach((select) => {
            if (event.target !== select) {
                select.value = ""
            }

            const emptyOptionSelected = select.value === null || select.value.trim().length === 0
            if (emptyOptionSelected) {
                select.style.fontWeight = "normal"
            } else {
                select.style.fontWeight = "600"
            }
        })
    }
}
