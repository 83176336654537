// Entry point for the build script in your package.json
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import * as ActiveStorage from "@rails/activestorage"
import LocalTime from "local-time"
import { Turbo } from "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"
import "./controllers"

window.Turbo = Turbo
LocalTime.start()
