import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["cb1", "cb2"]

  toggleBoth() {
    var cb1Check = this.cb1Target.checked,
        cb2Check = this.cb2Target.checked;

    if (cb1Check && cb2Check) {
      this.cb1Target.checked = false;
      this.cb2Target.checked = false;
    } else {
      this.cb1Target.checked = true;
      this.cb2Target.checked = true;
    }
  }
}
