import { Controller } from "@hotwired/stimulus"

const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export default class extends Controller {

  static targets = [ "datepickerValue", "panel", "monthName", "yearDisplay" ]
  initialize() {
    this.month = '';
    this.year = '';
    this.no_of_days = [];
    this.blankdays = [];
    this.days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    this.initDate();

    this.monthNameTarget.innerText = MONTH_NAMES[this.month];
    this.yearDisplayTarget.innerText = this.year;
  }

  initDate() {
    let today = new Date();
    this.month = today.getMonth();
    this.year = today.getFullYear();
    this.datepickerValueTarget.value = new Date(this.year, this.month, today.getDate()).toDateString();
  }

  isToday(date) {
    const today = new Date();
    const d = new Date(this.year, this.month, date);

    return today.toDateString() === d.toDateString() ? true : false;
  }

  getDateValue(date) {
    let selectedDate = new Date(this.year, this.month, date);
    this.datepickerValueTarget.value = selectedDate.toDateString();

    this.$refs.date.value = selectedDate.getFullYear() +"-"+ ('0'+ selectedDate.getMonth()).slice(-2) +"-"+ ('0' + selectedDate.getDate()).slice(-2);

    console.log(this.$refs.date.value);

    this.showDatepicker = false;
  }

  getNoOfDays() {
    let daysInMonth = new Date(this.year, this.month + 1, 0).getDate();

    // find where to start calendar day of week
    let dayOfWeek = new Date(this.year, this.month).getDay();
    let blankdaysArray = [];
    for ( var i=1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    let daysArray = [];
    for ( var i=1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    this.blankdays = blankdaysArray;
    this.no_of_days = daysArray;
  }

  toggleDatepicker(event) {
    event.preventDefault();
    this.panelTarget.classList.toggle('hidden');
  }

  hideOnEscape(event) {
    if (e.key == "Escape") {
      this.panelTarget.classList.add('hidden');
    }
  }

  hideDatepicker(event) {
    event.preventDefault();
    this.panelTarget.classList.add('hidden')
  }
}
